<template>
  <div class="row vh-100 m-0 mt-lg-5 me-lg-5">
    <div
      class="d-flex align-items-center justify-content-center justify-content-lg-end"
    >
      <form class="login-form" @submit.prevent="auth">
        <h6 class="fw-light text-center text-white mb-3">
          Voce acessará como funcionário
        </h6>
        <div class="card mb-0">
          <div class="card-body">
            <div class="text-center mb-3">
              <div
                class="d-inline-flex align-items-center justify-content-center my-2 d-lg-none"
              >
                <img src="@/assets/img/logos/icone-02.svg" class="h-48px" />
              </div>
              <h4 class="text-secondary mb-0">Conecte ao sistema</h4>
            </div>
            <div class="mb-3">
              <label class="form-label">CPF</label>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': v$.cpf.$errors.length,
                }"
                placeholder="000.000.000-00"
                v-model="v$.cpf.$model"
                v-maska="'###.###.###-##'"
              />
              <div class="form-text">
                Utilize o CPF para consultar seus agendamentos
              </div>
              <span
                v-if="v$.cpf.requiredIfKey.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
              <span v-if="v$.cpf.minLength.$invalid" class="invalid-feedback">
                Formato: 000.000.000-00
              </span>
            </div>
            <div class="mb-2">
              <div class="hr-with-text">
                <hr />
                <span class="px-2">OU</span>
                <hr />
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Chave</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.key.$errors.length }"
                placeholder="Chave"
                maxlength="36"
                v-model="v$.key.$model"
              />
              <div class="form-text">
                Utilize a chave para detalhar e operar o sistema
              </div>
              <span
                v-if="v$.key.requiredIfCpf.$invalid"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
              <span v-if="v$.key.minLength.$invalid" class="invalid-feedback">
                A senha deve ter pelo menos
                {{ v$.key.minLength.$params.min }} caracteres
              </span>
              <span v-if="v$.key.maxLength.$invalid" class="invalid-feedback">
                A senha deve ter no máximo
                {{ v$.key.maxLength.$params.max }} caracteres
              </span>
            </div>
            <div class="mb-3">
              <button
                type="submit"
                class="btn btn-secondary w-100"
                title="Acessar"
                :disabled="v$.$invalid"
              >
                Acessar
              </button>
            </div>
            <div class="text-center" title="Alterar perfil de acesso">
              <router-link to="/">Alterar perfil de acesso</router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, maxLength, requiredIf } from '@vuelidate/validators'
import { maska } from 'maska'

export default {
  name: 'CollaboratorAuth',

  setup() {
    return { v$: useVuelidate() }
  },

  directives: { maska },

  data() {
    return {
      backgroundImg: 'background-12.jpg',
      cpf: '',
      key: '',
    }
  },

  methods: {
    async auth() {
      if (!this.v$.$invalid) {
        try {
          if (this.cpf) {
            this.$router.push({
              name: 'CollaboratorPreviewSchedules',
              params: { cpf: this.cpf },
            })
          } else {
            this.$router.push({
              name: 'CollaboratorSchedulesDetails',
              params: { key: this.key },
            })
          }
        } catch (error) {
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      cpf: {
        requiredIfKey: requiredIf(!this.key),
        minLength: minLength(14),
        maxLength: maxLength(14),
      },
      key: {
        requiredIfCpf: requiredIf(!this.cpf),
        minLength: minLength(36),
        maxLength: maxLength(36),
      },
    }
  },

  created() {
    this.$store.dispatch('setBackground', this.backgroundImg)
  },
}
</script>

<style scoped>
.hr-with-text {
  display: flex;
  align-items: center;
  text-align: center;
}

.hr-with-text hr {
  flex: 1;
  margin: 0;
}
</style>
